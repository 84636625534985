import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.css";
import "vue-select/dist/vue-select.css";
import 'primeicons/primeicons.css'

// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import Login from "./components/Login.vue";
import Biobank from "./components/Biobank.vue";
import Collections from "./components/Collections.vue";
import Collection from "./components/Collection.vue";
import CollectionNew from "./components/CollectionNew.vue";
import People from "./components/People.vue";
import Person from "./components/Person.vue";
import PersonNew from "./components/PersonNew.vue";
import User from "./components/User.vue";
import AlsoKnown from "./components/AlsoKnown";
import AlsoKnownNew from "./components/AlsoKnownNew";
import Facts from "./components/Facts";

Vue.use(VueRouter);
// Vue.use(ElementUI);

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    routes: [
        {path: "/", redirect: "/biobank"},
        {path: "/login", component: Login},
        {path: "/user", component: User},
        {path: "/biobank", component: Biobank},
        {path: "/collections", component: Collections},
        // {path: "/subcollections", component: Collections},
        {path: "/collections/:id", component: Collection},
        {path: "/subcollections/:id", component: Collection},
        {path: "/collection-new/", component: CollectionNew},
        {path: "/also-known-new/", component: AlsoKnownNew},
        {path: "/also-known/:id", component: AlsoKnown},
        {path: "/facts/:collection_id", component: Facts},
        {path: "/people", component: People},
        {path: "/people/:id", component: Person},
        {path: "/person-new", component: PersonNew}
    ]
});
router.beforeEach((to, from, next) => {
    // user logged in <=> item "user" exists in localStorage
    if (!localStorage.getItem("user") && to.path !== "/login")
        return next("/login");
    next();
});

if (!document.cookie) {
    //user is not logged in
    localStorage.clear();
}

new Vue({
    el: "#app",
    router,
    render: h => h(App)
});
